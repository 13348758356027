require("./showroom.scss");

(function () {
    const toggle = (selector, index, className = "show") => {
        $(selector).removeClass(className);
        $(`${selector}:nth-child(${index + 1})`).addClass(className);
    };

    const toggleAll = (index) => {
        toggle(".showroom nav div", index, "active");
        toggle(".showroom nav div", index, "text-primary");
        toggle(".showroom-sections > *", index);
        toggle(".showroom-header-links > *", index);
    };

    $(".showroom nav div").click(function () {
        toggleAll($(this).index());
    });

    $(".showroom-section-item").click(function () {
        const clkTrackerImgId = Math.round(Math.random() * 10000000);
        let clickTrackerUrl = $(this).data("click-tracking-url");
        if (clickTrackerUrl) {
            const clickTrackerUrlContainsQueryStringWithParams = clickTrackerUrl.indexOf('?') >= 0 && clickTrackerUrl.indexOf('&');

            if (clickTrackerUrlContainsQueryStringWithParams) {
                clickTrackerUrl = `${clickTrackerUrl}&clkTrackerImgId=${clkTrackerImgId}`;
            }

            const clickTrackerImg = `<img src='${clickTrackerUrl}' style='display:none;' alt=""> </img>`;
            $("body").append(clickTrackerImg);
        }

        if (!window.optimizelyUtil) return;
        window.optimizelyUtil.track("click-homepage-showroom-driver");
    });

    window.googletag.cmd.push(function () {
        window.googletag.pubads().addEventListener('slotRenderEnded',
            function (event) {
                var slot = event.slot;
                var slotId = slot.getSlotElementId();
                if (slotId.includes("home_custom_1")) {
                    if (!event.isEmpty) {
                        $(".showroom-section-item.bodydriver").prev().css("display", "none");
                        $(".showroom-section-item.bodydriver").css("display", "block");
                    }
                }
            });
    });
})();
